import React from 'react';

import Container from './src/components/sitewideComponents/container';
import { MDXProvider } from "@mdx-js/react";
import "./src/css/style.css";
require("./src/css/prism-monokai.css")
// import "tailwindcss/dist/base.min.css";
const components = {
    wrapper: ({children}) => children
}

export const wrapRootElement = ({ element }) => (
    <MDXProvider components={components}>
        <Container>{element}</Container>
    </MDXProvider>
);