import React from 'react';

import {
  COLORS,
  COLOR_MODE_KEY,
  INITIAL_COLOR_MODE_CSS_PROP,
} from '../constants';

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  // Use null as initial state to avoid hydration mismatch
  const [colorMode, rawSetColorMode] = React.useState(null);

  // Only run on client-side
  React.useEffect(() => {
    // Ensure we're only running this in the browser
    if (typeof window === 'undefined') return;
    
    const root = window.document.documentElement;
    // Because colors matter so much for the initial page view, we're
    // doing a lot of the work in gatsby-ssr. That way it can happen before
    // the React component tree mounts.
    const initialColorValue = root.style.getPropertyValue(
      INITIAL_COLOR_MODE_CSS_PROP
    );

    rawSetColorMode(initialColorValue || 'light');
  }, []);

  const contextValue = React.useMemo(() => {
    function setColorMode(newValue) {
      // Ensure we're only running this in the browser
      if (typeof window === 'undefined') return;
      
      const root = window.document.documentElement;

      localStorage.setItem(COLOR_MODE_KEY, newValue);

      Object.entries(COLORS).forEach(([name, colorByTheme]) => {
        const cssVarName = `--color-${name}`;
        root.style.setProperty(cssVarName, colorByTheme[newValue]);
      });
      
      rawSetColorMode(newValue);
    }
    return {
      // Use light as fallback if colorMode is null (during SSR)
      colorMode: colorMode || 'light',
      setColorMode,
    };
  }, [colorMode, rawSetColorMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};
