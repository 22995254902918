import React from 'react';
import { useState, useEffect } from 'react';

const Background = () => {    
    const [isBrowser, setIsBrowser] = useState(false);
    
    useEffect(() => {
        setIsBrowser(true);
        
        const handleMouseMove = (e) => {
            // Mouse movement logic can be added here if needed
            // Removed the animation for now to fix the rebuild loop
        };
        
        if (typeof window !== 'undefined') {
            document.body.addEventListener('mousemove', handleMouseMove);
            return () => document.body.removeEventListener('mousemove', handleMouseMove);
        }
    }, []);

    if (!isBrowser) {
        return null; // Return null on server-side
    }

    return (
        <>      <div className="relative z-fix">
                    <div className="page-background">
                        <span className="bg-blob-span">
                            <svg 
                                id="Wobble" 
                                className="bg-blob" 
                                width="459" 
                                height="459" 
                                viewBox="0 0 459 459"
                            >
                                <path 
                                    d="M478,248.5c-1.22,75.56-54.86,151.77-80.47,174.54-23.84,21.19-92.15,55-149,55C194.27,478,137,476,98,429,54.69,376.81,19,321,19,248.5,19,198.55,37,145,62.06,114.65,107.48,59.62,171.7,19,248.5,19,311.63,19,384.3,31.74,418,85S478,184.88,478,248.5Z" 
                                    transform="translate(-19 -19)" 
                                    fill=""
                                />
                            </svg>
                        </span>
                    </div>
                </div>
        </>
    );
};

export default Background;
