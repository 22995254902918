import React, { useEffect, useState } from 'react';
import { ThemeContext } from '../themeContext';
import { motion } from 'framer-motion';

const DarkToggle = (props) => {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);
  const [mounted, setMounted] = useState(false);
  
  // After mounting, we can safely access the colorMode
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || !colorMode) {
    return null;
  }

  const toggleTheme = () => {
    setColorMode(colorMode === 'light' ? 'dark' : 'light');
  };

  return (
    <button 
      onClick={toggleTheme}
      aria-label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`}
      className="sm:block hover:text-accent transition duration-150 transform hover:scale-125 lst"
    >
      <div className="sr-only">Toggle dark mode</div>
      <div className="relative w-8 h-8 md:w-7 md:h-7">
        {colorMode === 'dark' ? (
          // Moon icon with animation
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            initial={{ scale: 0.6, rotate: -90 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ duration: 0.5, type: 'spring', stiffness: 200 }}
            className="absolute inset-0 w-full h-full fill-current md:mr-2 transform scale-125"
          >
            <motion.path
              d="M12 3a9 9 0 109 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 01-4.4 2.26 5.403 5.403 0 01-3.14-9.8c-.44-.06-.9-.1-1.36-.1z"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            />
          </motion.svg>
        ) : (
          // Sun icon with animation
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            initial={{ scale: 0.6, rotate: 90 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ duration: 0.5, type: 'spring', stiffness: 200 }}
            className="absolute inset-0 w-full h-full fill-current md:mr-2 transform scale-125"
          >
            <motion.circle
              cx="12"
              cy="12"
              r="5"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2, duration: 0.5 }}>
              <path d="M12 2v2M12 20v2M4.93 4.93l1.41 1.41M17.66 17.66l1.41 1.41M2 12h2M20 12h2M6.34 17.66l-1.41 1.41M19.07 4.93l-1.41 1.41" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" />
            </motion.g>
          </motion.svg>
        )}
      </div>
    </button>
  );
};

export default DarkToggle;